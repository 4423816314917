import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/Table";
import {
  db,
  collection,
  getDocs,
  ref,
  storage,
  getDownloadURL,
} from "../components/Firebase";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";

const Phase5 = () => {
  const [blur, setBlur] = useState("0px");
  const [outerMoreDetailsDisplay, setOuterMoreDetailsDisplay] =
    useState("none");
  const [userData, setUserData] = useState([]);
  const [oneUser, setOneUser] = useState([]);
  useEffect(() => {
    const allData = [];
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "phase5"));
      querySnapshot.forEach((ele) => {
        allData.push(ele.data());
      });
      setUserData(allData);
    };
    fetchData();
  }, []);

  let sr = 0;
  const navigate = useNavigate();

  const moreDeatils = (ele) => {
    if (ele.anyExperienceInCommunity) ele.anyExperienceInCommunity = "True";
    else ele.anyExperienceInCommunity = "False";

    if (ele.courseExperienceOfHosting) ele.courseExperienceOfHosting = "True";
    else ele.courseExperienceOfHosting = "False";

    setOuterMoreDetailsDisplay("flex");
    setBlur("4px");
    const oneUserArray = [ele];
    setOneUser(oneUserArray);
  };

  const handleCross = () => {
    setOuterMoreDetailsDisplay("none");
    setBlur("0px");
  };

  return (
    <>
      <Container fluid className="mb-5">
        {/* ************ MORE DETAILS DIV *********** */}

        <div
          className="outerMoreDetails"
          style={{ display: `${outerMoreDetailsDisplay}` }}
        >
          <div className="innerMoreDetails mt-2 w-auto">
            <div className="menuBarCrossDiv mb-3">
              <i
                class="fa-sharp fa-regular fa-circle-xmark menuBarCross"
                onClick={() => {
                  handleCross();
                }}
              ></i>
            </div>
            <h1 className="mainHeading2 text-dark text-center mb-4">
              All <span className="mainHeading">Details</span>
            </h1>
            <div style={{ overflowY: "scroll", height: "50vh" }}>
              <Table striped bordered hover className="m-0">
                {oneUser.map((ele) => {
                  return (
                    <>
                      <thead>
                        <tr>
                          <th className="ps-4">Name</th>
                          <td className="ps-4">{ele.name}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">Email</th>
                          <td className="ps-4">{ele.email}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">Phone</th>
                          <td className="ps-4">{ele.phNumber}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">College</th>
                          <td className="ps-4">{ele.collegeName}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">Course</th>
                          <td className="ps-4">{ele.courseName}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">CGPA</th>
                          <td className="ps-4">{ele.cgpa}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">Passout Year</th>
                          <td className="ps-4">{ele.passoutYear}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">DOB</th>
                          <td className="ps-4">{ele.dob}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">
                            Any Experience in Community Lead / Volunteer in the
                            past?
                          </th>
                          <td className="ps-4">
                            {ele.anyExperienceInCommunity}
                          </td>
                        </tr>
                        <tr>
                          <th className="ps-4">
                            Course Experience of hosting events in the past?
                          </th>
                          <td className="ps-4">
                            {ele.courseExperienceOfHosting}
                          </td>
                        </tr>
                        <tr>
                          <th className="ps-4">Recommendation Letter</th>
                          {ele.letter ? (
                            <>
                              <td
                                className="ps-4 tableLink"
                                onClick={async () => {
                                  let link = `gs://student-ambassador-program.appspot.com/recommendation-letters/${ele.email}-letter.pdf`;
                                  let nlink = link.replaceAll(" ", "");
                                  const fileRef = ref(storage, nlink);
                                  const downloadURL = await getDownloadURL(
                                    fileRef
                                  );
                                  localStorage.setItem("url", downloadURL);
                                  navigate("/letter-viewer");
                                }}
                              >
                                Link
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="ps-4">Not Uploaded!</td>
                            </>
                          )}
                        </tr>
                        <tr>
                          <th className="ps-4">Video Link</th>
                          <td className="ps-4">
                            <a
                              className="tableLink"
                              target="_blank"
                              rel="noreferrer"
                              href={ele.videoLink}
                            >
                              {ele.videoLink}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th className="ps-4">
                            Why you want to be Campus Ambassador for
                            Alphanumeric Ideas at your campus?
                          </th>
                          <td className="ps-4">{ele.whyYouWant}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">
                            How you got to know about this program
                          </th>
                          <td className="ps-4">{ele.knowAboutProgram}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">
                            What excites you the most about this program?
                          </th>
                          <td className="ps-4 w-40">{ele.whatExcites}</td>
                        </tr>
                        <tr>
                          <th className="ps-4">
                            Any other details you would love to share?
                          </th>
                          <td className="ps-4">{ele.otherDetails}</td>
                        </tr>
                      </thead>
                    </>
                  );
                })}
              </Table>
            </div>
          </div>
        </div>

        {/* ************ END MORE DETAILS DIV *********** */}

        <div
          className="row justify-content-center"
          style={{ filter : `blur(${blur})` }}
        >
          <div className="col-12">
            <div className="row justify-content-center">
              <div
                className="col-md-2 text-center phaseToggle"
                onClick={() => {
                  navigate("/users");
                }}
              >
                Application Under review
              </div>
              <div
                className="col-md-2 text-center phaseToggle"
                onClick={() => {
                  navigate("/phase2");
                }}
              >
                Shortlisted for Community Expert 
              </div>
              <div
                className="col-md-2 text-center phaseToggle"
                onClick={() => {
                  navigate("/phase3");
                }}
              >
                Shortlisted for Interview
              </div>
              <div
                className="col-md-2 text-center phaseToggle"
                onClick={() => {
                  navigate("/phase4");
                }}
              >
               Assignment Submission
              </div>
              <div
               style={{
                backgroundColor: "#fb2300",
                color: "white",
                border: "none",
              }}
                className="col-md-2 text-center phaseToggle"
                onClick={() => {
                  navigate("/phase5");
                }}
              >
                GIGT Student Ambassador
              </div>
              <div
                className="col-md-2 text-center phaseToggle"
                onClick={() => {
                  navigate("/rejected");
                }}
              >
                Rejected Users
              </div>
            </div>
          </div>
          <div className="col-md-11 text-center col-11 mt-5 mb-5">
            <h1 className="mainHeading2 text-dark text-center">
              Users <span className="mainHeading">Data</span>
            </h1>
            <Table striped bordered hover className="mt-5">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>College</th>
                  <th>CGPA</th>
                  <th>Action</th>
                </tr>
              </thead>
              {userData.map((ele) => {
                sr++;
                return (
                  <>
                    <tbody>
                      <tr>
                        <td>{sr}</td>
                        <td>{ele.name}</td>
                        <td>{ele.email}</td>
                        <td>{ele.phNumber}</td>
                        <td>{ele.collegeName}</td>
                        <td>{ele.cgpa}</td>
                        <td>
                          <div className="d-flex justify-content-around">
                            <Button
                              variant="success"
                              onClick={() => moreDeatils(ele)}
                            >
                              more details
                            </Button>
                            {/* <Button
                              variant="success"
                              onClick={() => changePhase(ele)}
                            >
                              Go to Phase 1
                            </Button> */}

                            {/* <Button
                              variant="danger"
                              onClick={() => {
                                rejectStudent(ele);
                              }}
                            >
                              Reject
                            </Button> */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              })}
            </Table>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Phase5;
