import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/esm/Container";
import { useNavigate } from "react-router-dom";

const ApplicationStatus = ({ currentUserEmail, checkingAuth, phase }) => {
  const navigate = useNavigate();

  let bgColor1 = "white";
  let txtColor1 = "black";
  let border1 = "";

  let bgColor2 = "white";
  let txtColor2 = "black";
  let border2 = "";

  let bgColor3 = "white";
  let txtColor3 = "black";
  let border3 = "";

  let bgColor4 = "white";
  let txtColor4 = "black";
  let border4 = "";

  let bgColor5 = "white";
  let txtColor5 = "black";
  let border5 = "";

  if (phase === 1) {
    bgColor1 = "#fb2300";
    txtColor1 = "white";
    border1 = "none";
  }

  if (phase === 2) {
    bgColor1 = "#fb2300";
    txtColor1 = "white";
    border1 = "none";

    bgColor2 = "#fb2300";
    txtColor2 = "white";
    border2 = "none";
  }

  if (phase === 3) {
    bgColor1 = "#fb2300";
    txtColor1 = "white";
    border1 = "none";

    bgColor2 = "#fb2300";
    txtColor2 = "white";
    border2 = "none";

    bgColor3 = "#fb3300";
    txtColor3 = "white";
    border3 = "none";
  }

  if (phase === 4) {
    bgColor1 = "#fb2300";
    txtColor1 = "white";
    border1 = "none";

    bgColor2 = "#fb2300";
    txtColor2 = "white";
    border2 = "none";

    bgColor3 = "#fb3300";
    txtColor3 = "white";
    border3 = "none";

    bgColor4 = "#fb3300";
    txtColor4 = "white";
    border4 = "none";
  }

  if (phase === 5) {
    bgColor1 = "#fb2300";
    txtColor1 = "white";
    border1 = "none";

    bgColor2 = "#fb2300";
    txtColor2 = "white";
    border2 = "none";

    bgColor3 = "#fb3300";
    txtColor3 = "white";
    border3 = "none";

    bgColor4 = "#fb3300";
    txtColor4 = "white";
    border4 = "none";

    bgColor5 = "#fb3300";
    txtColor5 = "white";
    border5 = "none";
  }

  return (
    <>
      <Container fluid className="mb-5">
        <div className="row justify-content-center">
          <div
            className="col-md-2 col-8 phase"
            style={{
              backgroundColor: `${bgColor1}`,
              color: `${txtColor1}`,
              border: `${border1}`,
            }}
          >
            Application Under review
          </div>
          <div
            className="col-md-2 col-8 phase"
            style={{
              backgroundColor: `${bgColor2}`,
              color: `${txtColor2}`,
              border: `${border2}`,
            }}
          >
            Shortlisted for Community Expert Review
          </div>
          <div
            className="col-md-2 col-8 phase"
            style={{
              backgroundColor: `${bgColor3}`,
              color: `${txtColor3}`,
              border: `${border3}`,
            }}
          >
            Shortlisted for Interview
          </div>
          <div
            className="col-md-2 col-8 phase"
            style={{
              backgroundColor: `${bgColor4}`,
              color: `${txtColor4}`,
              border: `${border4}`,
            }}
          >
            You are almost There You Have to Submit Assignment With in 14 Days
          </div>
          <div
            className="col-md-2 col-8 phase"
            style={{
              backgroundColor: `${bgColor5}`,
              color: `${txtColor5}`,
              border: `${border5}`,
            }}
          >
            You are Finally a GIGT Student Ambassador
          </div>

          <div className="col-md-8 mt-5 mb-5 d-flex justify-content-around">
            {checkingAuth ? (
              <>
                {currentUserEmail === "mohit@alphanumericideas.com" ||
                currentUserEmail === "prakeet@alphanumericideas.com" ||
                currentUserEmail === "abhishekgautam@alphanumericideas.com" ||
                currentUserEmail === "zippinder@alphanumericideas.com" ? (
                  <>
                    <Button
                      className="mainBtn"
                      onClick={() => {
                        navigate("/users");
                      }}
                    >
                      DASHBOARD
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <Button
              className="mainBtn"
              onClick={() => {
                navigate("/about");
              }}
            >
              ABOUT
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ApplicationStatus;
