import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import logo from "../images/logo.png";
import googleLogo from "../images/google-logo.png";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import Loader from "../components/Loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  db,
  doc,
  setDoc,
  auth,
  storage,
  gProvider,
  signInWithPopup,
  ref,
  uploadBytes,
  signOut,
} from "./Firebase";

const Navbar = ({ checkingAuth, menubarName, currentUserEmail, isUpdate }) => {
  const [signInTranslate, setSignInTranslate] = useState(100);
  const [signInOpacity, setSignInOpacity] = useState(0);
  const [signInZIndex, setSignInZindex] = useState(-1);

  const [firstTranslate, setFirstTranslate] = useState(100);
  const [firstDisplay, setFirstDisplay] = useState(0);
  const [firstZIndex, setFirstZindex] = useState(-1);

  const [secondOpacity, setSecondOpacity] = useState(0);
  const [secondZIndex, setSecondZindex] = useState(-1);

  const [thirdOpacity, setThirdOpacity] = useState(0);
  const [thirdZIndex, setThirdZindex] = useState(-1);

  const [fourthOpacity, setFourthOpacity] = useState(0);
  const [fourthZIndex, setFourthZindex] = useState(-1);

  const [fifthOpacity, setFifthOpacity] = useState(0);
  const [fifthZIndex, setFifthZindex] = useState(-1);

  const [sixthOpacity, setSixthOpacity] = useState(0);
  const [sixthZIndex, setSixthZindex] = useState(-1);

  const [fistYesNoBtn, setFistYesNoBtn] = useState(true);
  const [secondYesNoBtn, setSecondYesNoBtn] = useState(true);

  const [errorText, setErrorText] = useState(0);
  const [errorTranslate, setErrorTranslate] = useState(1600);

  const [phError, setPhError] = useState("none");
  const [phTranslate, setPhTranslate] = useState(0);

  const [cgpaError, setCgpaError] = useState("none");
  const [cgpaTranslate, setCgpaTranslate] = useState(50);

  const [passwordError, setPasswordError] = useState("none");

  const [batchError, setBatchError] = useState("none");
  const [batchTranslate, setBatchTranslate] = useState(50);

  const [outerMenuDisplay, setOuterMenuDisplay] = useState("none");
  const [outerMenuBarZindex, setOuterMenuBarZindex] = useState(-10);

  const [innerMenuTranslate, setInnerMenuTranslate] = useState("25rem");
  const [innerMenuOpacity, setInnerMenuOpacity] = useState(0);

  const [isLoading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    signInEmail: "",
    password: "",
    email: "",
    phNumber: "",
    dob: "",
    collegeName: "",
    courseName: "",
    passoutYear: "",
    cgpa: "",
    videoLink: "",
    whyYouWant: "",
    whatExcites: "",
    otherDetails: "",
  });

  formData.email = currentUserEmail;

  const navigate = useNavigate();

  // ********** ERROR REGEX VARIABLES *****

  const mobileNumberRegex = /^\d{10}$/;
  const cgpaRegex = /^\d+\.\d+$/;
  const passwordRegex = /^.{6,}$/;
  const batchRegex = /^20\d{2}$/;

  // ********** END ERROR REGEX VARIABLES *****

  // ********** BUTTON COLOR VARIABLES *******

  let firstYesBtnColor = "#fb2300";
  let firstNoBtnColor = "black";
  let secondYesBtnColor = "#fb2300";
  let secondNoBtnColor = "black";

  // ********** END BUTTON COLOR VARIABLES *******

  // ************ LOGIN FUNCTION *************

  const login = () => {
    document.body.style.overflowY = "scroll";

    signInWithPopup(auth, gProvider).then(() => {
      setOuterMenuDisplay("none");
      setSignInZindex(-1);
      setSignInTranslate(100);
      setSignInOpacity(0);
    });
  };

  // ************ END LOGIN FUNCTION *************

  // *********** LOGOUT FUNCTION ************

  const logout = () => {
    document.body.style.overflowY = "scroll";
    signOut(auth)
      .then(() => {
        setOuterMenuDisplay("none");
        setInnerMenuTranslate("25rem");
        setInnerMenuOpacity(0);
        setOuterMenuBarZindex(-10);
        navigate("/");
      })
      .catch((error) => {
        alert(error.message);
        setOuterMenuDisplay("none");
        setInnerMenuTranslate("25rem");
        setInnerMenuOpacity(0);
        setOuterMenuBarZindex(-10);
      });
  };

  // *********** END LOGOUT FUNCTION ************

  // ************** HANDLE NAVBAR FUNCTION ************

  const handleNavbar = (hit) => {
    if (hit === "cross") {
      document.body.style.overflowY = "scroll";
      setOuterMenuDisplay("none");
      setInnerMenuTranslate("25rem");
      setInnerMenuOpacity(0);
      setOuterMenuBarZindex(-10);
    } else {
      document.body.style.overflow = "hidden";
      setOuterMenuDisplay("flex");
      setInnerMenuTranslate("0");
      setInnerMenuOpacity(1);
      setOuterMenuBarZindex(10);
    }
  };

  // ************** END HANDLE NAVBAR FUNCTION ************

  // ********* HANDLE INPUT FUNCTION *********

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });

    if (name === "phNumber") {
      let checkNumber = mobileNumberRegex.test(value);
      if (!checkNumber) {
        setPhError("block");
        setPhTranslate(0);
      } else {
        setPhError("none");
        setPhTranslate(30);
      }
    }

    if (name === "cgpa") {
      let checkCgpa = cgpaRegex.test(value);
      if (!checkCgpa) {
        setCgpaError("block");
        setCgpaTranslate(0);
      } else {
        setCgpaError("none");
        setCgpaTranslate(50);
      }
    }

    if (name === "password") {
      let checkPassword = passwordRegex.test(value);
      if (!checkPassword) {
        setPasswordError("block");
      } else {
        setPasswordError("none");
      }
    }

    if (name === "passoutYear") {
      let checkBatch = batchRegex.test(value);
      if (!checkBatch) {
        setBatchError("block");
        setBatchTranslate(0);
      } else {
        setBatchError("none");
        setBatchTranslate(50);
      }
    }
  };

  // ********* END HANDLE INPUT FUNCTION *********

  // ************ SHOW SIGN IN DIV FUNCTION *************

  const showSignInDiv = () => {
    setInnerMenuTranslate("25rem");
    setInnerMenuOpacity(0);
    setOuterMenuBarZindex(-10);

    setSignInZindex(100);
    setSignInTranslate(0);
    setSignInOpacity(1);
  };

  // ************ END SHOW SIGN IN DIV FUNCTION *************

  // ********* FIRST SLIDE SHOW DIV FUNCTION *********

  const showDiv = () => {
    document.body.style.overflow = "hidden";
    setInnerMenuTranslate("25rem");
    setInnerMenuOpacity(0);
    setOuterMenuBarZindex(-10);

    setFirstDisplay("visible");
    setFirstZindex(100);
    setFirstTranslate(0);

    setSecondOpacity(0);
    setSecondZindex(-4);

    setFourthOpacity(0);
    setFourthZindex(-4);

    setFifthOpacity(0);
    setFifthZindex(-4);

    setSixthOpacity(0);
    setSixthZindex(-4);

    setThirdOpacity(0);
    setThirdZindex(-4);
  };

  // ********* END FIRST SLIDE SHOW DIV FUNCTION *********

  // ********** ALL SLIDES CHANGING FUNCTION *************

  const nextSlide = (check) => {
    const { fName, lName, email, phNumber, dob } = formData;
    if (check === "secondShow") {
      if (fName && lName && email && phNumber && dob) {
        if (phError === "none" && passwordError === "none") {
          setFirstDisplay("hidden");
          setFirstZindex(-4);

          setThirdOpacity(0);
          setThirdZindex(-4);

          setFourthOpacity(0);
          setFourthZindex(-4);

          setFifthOpacity(0);
          setFifthZindex(-4);

          setSixthOpacity(0);
          setSixthZindex(-4);

          setSecondOpacity(1);
          setSecondZindex(4);

          setErrorText(0);
          setErrorTranslate(1600);
        }
      } else {
        setErrorText(1);
        setErrorTranslate(0);
      }
    }
    const { collegeName, courseName, passoutYear, cgpa } = formData;
    if (check === "thirdShow") {
      if (collegeName && courseName && passoutYear && cgpa) {
        if (cgpaError === "none" && batchError === "none") {
          setSecondOpacity(0);
          setSecondZindex(-4);

          setFirstDisplay("hidden");
          setFirstZindex(-4);

          setFourthOpacity(0);
          setFourthZindex(-4);

          setFifthOpacity(0);
          setFifthZindex(-4);

          setSixthOpacity(0);
          setSixthZindex(-4);

          setThirdOpacity(1);
          setThirdZindex(4);

          setErrorText(0);
          setErrorTranslate(1600);
        }
      } else {
        setErrorText(1);
        setErrorTranslate(0);
      }
    }

    if (check === "fourthShow") {
      setThirdOpacity(0);
      setThirdZindex(-4);

      setFirstDisplay("hidden");
      setFirstZindex(-4);

      setSecondOpacity(0);
      setSecondZindex(-4);

      setFifthOpacity(0);
      setFifthZindex(-4);

      setSixthOpacity(0);
      setSixthZindex(-4);

      setFourthOpacity(1);
      setFourthZindex(4);
    }

    if (check === "fifthShow") {
      setFirstDisplay("hidden");

      setFirstZindex(-4);

      setThirdOpacity(0);
      setThirdZindex(-4);

      setSecondOpacity(0);
      setSecondZindex(-4);

      setSixthOpacity(0);
      setSixthZindex(-4);

      setFourthOpacity(0);
      setFourthZindex(-4);

      setFifthOpacity(1);
      setFifthZindex(4);
    }

    const { videoLink } = formData;

    if (check === "sixthShow") {
      if (videoLink) {
        setFirstDisplay("hidden");
        setFirstZindex(-4);

        setThirdOpacity(0);
        setThirdZindex(-4);

        setSecondOpacity(0);
        setSecondZindex(-4);

        setSixthOpacity(0);
        setSixthZindex(-4);

        setFourthOpacity(0);
        setFourthZindex(-4);

        setFifthOpacity(0);
        setFifthZindex(-4);

        setSixthOpacity(1);
        setSixthZindex(4);

        setErrorText(0);
        setErrorTranslate(1600);
      } else {
        setErrorText(1);
        setErrorTranslate(0);
      }
    }
  };

  // ********** END ALL SLIDES CHANGING FUNCTION *************

  // ************ BUTTON TOGGLE FUNCTION *********

  const firstYesNoToggle = (e, isYes) => {
    e.preventDefault();
    if (isYes === "yes") {
      setFistYesNoBtn(true);
    } else {
      setFistYesNoBtn(false);
    }
  };

  const secondYesNoToggle = (e, isYes) => {
    e.preventDefault();
    if (isYes === "yes") {
      setSecondYesNoBtn(true);
    } else {
      setSecondYesNoBtn(false);
    }
  };

  if (!fistYesNoBtn) {
    firstNoBtnColor = "#fb2300";
    firstYesBtnColor = "black";
  } else {
    firstNoBtnColor = "black";
    firstYesBtnColor = "#fb2300";
  }

  if (!secondYesNoBtn) {
    secondNoBtnColor = "#fb2300";
    secondYesBtnColor = "black";
  } else {
    secondNoBtnColor = "black";
    secondYesBtnColor = "#fb2300";
  }

  // ************ END BUTTON TOGGLE FUNCTION *********

  // ************* DATA SUBMIT FUNCTION *************

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    document.body.style.overflowY = "scroll";
    e.preventDefault();
    let letter = false;
    if (selectedFile) letter = true;

    const {
      fName,
      lName,
      email,
      phNumber,
      dob,
      collegeName,
      courseName,
      passoutYear,
      cgpa,
      videoLink,
      knowAboutProgram,
      whyYouWant,
      whatExcites,
      otherDetails,
    } = formData;
    const fullName = fName + " " + lName;

    if (whyYouWant && whatExcites && otherDetails) {
      await setDoc(doc(db, "phase1", email), {
        name: fullName,
        email,
        phNumber,
        dob,
        collegeName,
        courseName,
        passoutYear,
        cgpa,
        anyExperienceInCommunity: fistYesNoBtn,
        courseExperienceOfHosting: secondYesNoBtn,
        videoLink,
        knowAboutProgram,
        letter: letter,
        whyYouWant,
        whatExcites,
        otherDetails,
        phase: 1,
      });

      const folderRef = ref(storage, "recommendation-letters");
      const fileRef = ref(folderRef, `${email}-letter.pdf`);
      uploadBytes(fileRef, selectedFile);

      setSixthOpacity(0);
      setSixthZindex(-4);
      setLoading(true);

      const response = await axios.post(
        "https://us-central1-student-ambassador-program.cloudfunctions.net/server/api/applying-mail",
        {
          name: fullName,
          email: email,
        }
      );
      if (response) {
        setOuterMenuDisplay("none");
        setLoading(false);
        window.location.reload();
      }
    } else {
      setErrorText(1);
      setErrorTranslate(0);
    }
  };

  // ************* END DATA SUBMIT FUNCTION *************

  // ********** FORM CLOSE FUNCTION ************

  const closeForm = (check) => {
    document.body.style.overflowY = "scroll";
    if (check === "signIn") {
      setOuterMenuDisplay("none");
      setSignInTranslate(100);
      setSignInOpacity(0);
      setSignInZindex(-4);

      setErrorText(0);
      setErrorTranslate(1600);
    }
    if (check === "first") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);

      setFirstZindex(-4);
    }
    if (check === "second") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setSecondOpacity(0);
      setSecondZindex(-4);
    }
    if (check === "third") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setThirdOpacity(0);
      setThirdZindex(-4);
    }
    if (check === "fourth") {
      setOuterMenuDisplay("none");
      setFourthOpacity(0);
      setFourthZindex(-4);
    }
    if (check === "fifth") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setFifthOpacity(0);
      setFifthZindex(-4);
    }
    if (check === "sixth") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setSixthOpacity(0);
      setSixthZindex(-4);
    }
  };

  // ********** END FORM CLOSE FUNCTION ************
  return (
    <>
      {/* **************** NAVBAR ****************** */}

      {isLoading ? (
        <>
          <div>
            <Loader />
          </div>
        </>
      ) : (
        <></>
      )}

      <div
        style={{
          display: `${outerMenuDisplay}`,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "absolute",
          height: "100vh",
          right: "0",
          left: "0",
          zIndex: "2",
        }}
      ></div>
      <div className="outerMenuBar" style={{ zIndex: `${outerMenuBarZindex}` }}>
        <div
          className="menuBar"
          style={{
            transition: "ease-in-out .6s",
            transform: `translateX(${innerMenuTranslate})`,
            opacity: `${innerMenuOpacity}`,
          }}
        >
          <div className="menuBarCrossDiv mb-5">
            <i
              class="fa-sharp fa-regular fa-circle-xmark menuBarCross"
              onClick={() => {
                handleNavbar("cross");
              }}
            ></i>
          </div>
          {checkingAuth ? (
            !isUpdate ? (
              <>
                <div className="d-flex flex-column align-items-center">
                  <Button className="mb-1 registerBtn" onClick={showDiv}>
                    UPDATE PROFILE
                  </Button>
                  <br />
                  <Button className="mb-5 signInBtn" onClick={logout}>
                    LOG OUT
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="d-flex flex-column">
                    <div className="mb-3 nameText">{menubarName}</div>
                    <div className="mb-5 emailText">{currentUserEmail} </div>
                  </div>
                  <div
                    className="logoutBtn mt-5 mb-4"
                    onClick={() => {
                      document.body.style.overflowY = "scroll";
                      setOuterMenuDisplay("none");
                      setInnerMenuTranslate("25rem");
                      setInnerMenuOpacity(0);
                      setOuterMenuBarZindex(-10);
                      navigate("/");
                    }}
                  >
                    HOME
                  </div>
                  <div
                    className="logoutBtn mb-4"
                    onClick={() => {
                      document.body.style.overflowY = "scroll";
                      setOuterMenuDisplay("none");
                      setInnerMenuTranslate("25rem");
                      setInnerMenuOpacity(0);
                      setOuterMenuBarZindex(-10);
                      navigate("/about");
                    }}
                  >
                    ABOUT
                  </div>
                  <div className="logoutBtn" onClick={logout}>
                    LOG OUT
                  </div>
                </div>
              </>
            )
          ) : (
            <>
              <div className="d-flex flex-column align-items-center">
                <Button className="mb-5 signInBtn" onClick={showSignInDiv}>
                  SIGN IN
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      <Container fluid>
        <div className="row justify-content-center Navbar align-items-center">
          <div className="col-md-3 col-0 "></div>
          <div className="col-md-6 text-center col-10 pt-3 pb-3">
            <Image
              fluid
              src={logo}
              alt="Logo"
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-md-3 col-2 text-center">
            <i
              class="fa-sharp fa-solid fa-bars hamburger"
              onClick={handleNavbar}
            ></i>
          </div>
        </div>
      </Container>

      {/* ********************* END NAVBAR ***************** */}

      {/* ************ SIGN IN DIV ************ */}

      <div
        className="outerDiv"
        style={{
          transition: "all 0.8s ease-in",
          opacity: `${signInOpacity}`,
          transformOrigin: "bottom",
          transform: `translateY(${signInTranslate}%)`,
          zIndex: `${signInZIndex}`,
        }}
      >
        <div className="col-md-5 col-10 innerDiv" id="hiddenDiv">
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("signIn");
              }}
            ></i>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12 col-12 desktopSignIn">
              <div className="text-center mt-0 mb-4">
                <div
                  className="row signInGoogle justify-content-center align-items-center"
                  onClick={login}
                >
                  <div className="col-3 text-center">
                    <Image fluid src={googleLogo}></Image>
                  </div>
                  <div className="col-9 text-center signInText">
                    SIGN IN WITH GOOGLE
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-12 mobileSignIn">
              <div className="text-center mt-0 mb-4">
                <div
                  className="row signInGoogle justify-content-center align-items-center"
                  onClick={login}
                >
                  <div className="col-3 text-center">
                    <Image fluid src={googleLogo}></Image>
                  </div>
                  <div className="col-7 signInText">SIGN IN</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ************ END SIGN IN DIV ************** */}

      {/* ********* UPDATE PROFILE DIV ************ */}

      <div
        className="outerDiv"
        style={{
          transition: "all 0.8s ease-in",
          transformOrigin: "bottom",
          transform: `translateY(${firstTranslate}%)`,
          zIndex: `${firstZIndex}`,
          visibility: `${firstDisplay}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv" id="hiddenDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("first");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                Personal <span className="mainHeading">Details</span>{" "}
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
                transform: `translateY(${errorTranslate}%)`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-11 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-3 col-12 formLable mb-4 mobileNone">
                    My name is
                  </div>
                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="fName"
                        value={formData.fName}
                        onChange={handleInput}
                        placeholder="First Name*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="lName"
                        value={formData.lName}
                        onChange={handleInput}
                        placeholder="Last Name*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-6 col-12 mb-4 formLable mobileNone">
                    and the best email to reach is
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="email">
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInput}
                        placeholder="Email Address*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-5 col-12 mb-4 formLable mobileNone">
                    or you can contact me at
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="number">
                      <Form.Control
                        type="text"
                        name="phNumber"
                        value={formData.phNumber}
                        onChange={handleInput}
                        placeholder="9876543210*"
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-12 mb-4"
                    style={{
                      transformOrigin: "right",
                      transition: "all ease-in-out .3s",
                      transform: `translateX(${phTranslate}%)`,
                      color: "#fb2300",
                      display: `${phError}`,
                    }}
                  >
                    Match pattern [9101234567]
                  </div>

                  <div className="col-lg-5 col-12 mb-4 formLable mobileNone">
                    and my date of birth is
                  </div>

                  <div className="col-lg-3 col-12 mb-4">
                    <Form.Group controlId="date">
                      <Form.Control
                        type="text"
                        name="dob"
                        value={formData.dob}
                        onChange={handleInput}
                        placeholder="Date of Birth"
                        onFocus={(e) => (e.target.type = "date")}
                        max="datePickerId"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-2 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("secondShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********* END UPDATE PROFILE DIV ************ */}

      {/* ********** SECOND HIDDEN DIV ************* */}

      <div
        className="outerDiv"
        style={{
          opacity: `${secondOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${secondZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("second");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                College <span className="mainHeading">Details</span>{" "}
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
                transform: `translateY(${errorTranslate}%)`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-11 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-3 col-12 formLable mb-4 mobileNone">
                    I studied in
                  </div>
                  <div className="col-lg-6 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="collegeName"
                        value={formData.collegeName}
                        onChange={handleInput}
                        placeholder="College Name*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-3 col-12 formLable mb-4 mobileNone"></div>

                  <div className="col-lg-3 col-12 mb-4 formLable mobileNone">
                    in the field of
                  </div>

                  <div className="col-lg-5 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="courseName"
                        value={formData.courseName}
                        onChange={handleInput}
                        placeholder="B.Tech*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-12 formLable mb-4 mobileNone"></div>

                  <div className="col-lg-4 col-12 mb-4 formLable mobileNone">
                    and my CGPA is
                  </div>

                  <div className="col-lg-3 col-12 mb-4">
                    <Form.Group controlId="number">
                      <Form.Control
                        type="text"
                        name="cgpa"
                        value={formData.cgpa}
                        onChange={handleInput}
                        placeholder="CGPA*"
                      />
                    </Form.Group>
                  </div>

                  <div
                    className="col-lg-3 col-12 mb-4"
                    style={{
                      transformOrigin: "right",
                      transition: "all ease-in-out .3s",
                      transform: `translate(${cgpaTranslate}%)`,
                      color: "#fb2300",
                      display: `${cgpaError}`,
                    }}
                  >
                    Please match pattern [9.0]
                  </div>

                  <div className="col-lg-2 col-12 mb-4 mobileNone"></div>

                  <div className="col-lg-4 col-12 mb-4 formLable mobileNone">
                    in the batch of
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="number">
                      <Form.Control
                        type="text"
                        name="passoutYear"
                        value={formData.passoutYear}
                        onChange={handleInput}
                        placeholder="Passout Year"
                      />
                    </Form.Group>
                  </div>

                  <div
                    className="col-lg-3 col-12 mb-4"
                    style={{
                      transformOrigin: "right",
                      transition: "all ease-in-out .3s",
                      transform: `translateX(${batchTranslate}%)`,
                      color: "#fb2300",
                      display: `${batchError}`,
                    }}
                  >
                    Please match pattern [ 2022 ]
                  </div>
                </div>
                <div className="text-center mt-2 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("thirdShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********* END SECOND HIDDEN DIV ********* */}

      {/* ********* THIRD HIDDEN DIV ******** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${thirdOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${thirdZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("third");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "40vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                Past <span className="mainHeading">Experience</span>
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-9 col-12 formLable mb-2">
                    Any Experience in Community Lead / Volunteer in the past?
                  </div>

                  <div className="col-lg-3 col-12 mb-4">
                    <button
                      className="yesBtn"
                      style={{ backgroundColor: `${firstYesBtnColor}` }}
                      onClick={(e) => {
                        firstYesNoToggle(e, "yes");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="noBtn"
                      style={{ backgroundColor: `${firstNoBtnColor}` }}
                      onClick={(e) => {
                        firstYesNoToggle(e, "no");
                      }}
                    >
                      No
                    </button>
                  </div>

                  <div className="col-lg-9 col-12 formLable mb-2">
                    Course Experience of hosting events in the past?
                  </div>
                  <div className="col-lg-3 col-12 mb-4">
                    <button
                      className="yesBtn"
                      style={{ backgroundColor: `${secondYesBtnColor}` }}
                      onClick={(e) => {
                        secondYesNoToggle(e, "yes");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="noBtn"
                      style={{ backgroundColor: `${secondNoBtnColor}` }}
                      onClick={(e) => {
                        secondYesNoToggle(e);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className="text-center mt-3 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("fourthShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>{" "}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********* END THIRD HIDDEN DIV ********* */}

      {/* ******** FOURTH HIDDEN DIV *********** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${fourthOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${fourthZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("fourth");
              }}
            ></i>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">Recommendations</h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-8 col-12 formLable mb-4">
                    Recommendation Letter by TPO / Registrar (Optional)
                  </div>
                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="file">
                      <Form.Control
                        type="file"
                        accept=".pdf"
                        name="uploadedFile"
                        onChange={handleFileUpload}
                        placeholder="College Name*"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-4 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("fifthShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* *********** END FOURTH HIDDEN DIV ********* */}

      {/* *********** FIFTH HIDDEN DIV *********** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${fifthOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${fifthZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("fifth");
              }}
            ></i>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                About <span className="mainHeading">You</span>
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
                // transform: `translateY(${errorTranslate}%)`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div
                  className="row align-items-center"
                  style={{
                    height: "40vh",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div className="fs-4 col-lg-7 col-12 formLable mb-4">
                    Short Intro Video Explaining yourself <br /> and experience
                    in 180 seconds
                  </div>
                  <div className="col-lg-5 col-12 mb-4">
                    <Form.Group controlId="url">
                      <Form.Control
                        type="url"
                        name="videoLink"
                        value={formData.videoLink}
                        onChange={handleInput}
                        placeholder="Enter Link*"
                      />
                    </Form.Group>
                  </div>

                  <div className="fs-4 col-lg-12 col-12 formLable mb-4">
                    How you got to know about this program
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="knowAboutProgram"
                        value={formData.knowAboutProgram}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-4 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("sixthShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********** END FIFTH HIDDEN DIV ********** */}

      {/* ********** SIXTH HIDDEN DIV *********** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${sixthOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${sixthZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("sixth");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                What Excites <span className="mainHeading">You the Most?</span>{" "}
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="fs-4 col-lg-12 col-12 formLable mb-2">
                    Why you want to be Campus Ambassador for Alphanumeric Ideas
                    at your campus?
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="whyYouWant"
                        value={formData.whyYouWant}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>

                  <div className="fs-4 col-lg-12 col-12 formLable mb-2">
                    What excites you the most about this program?
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="whatExcites"
                        value={formData.whatExcites}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>

                  <div className="fs-4 col-lg-12 col-12 formLable mb-2">
                    Any other details you would love to share?
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="otherDetails"
                        value={formData.otherDetails}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-4 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* *********** END SIXTH HIDDEN DIV *********** */}
    </>
  );
};

export default Navbar;
