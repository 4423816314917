import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Image from "react-bootstrap/Image";
import googleLogo from "../images/google-logo.png";
import mainImg from "../images/mainImage.png";
import Form from "react-bootstrap/Form";
import Loader from "../components/Loader";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  db,
  doc,
  setDoc,
  auth,
  gProvider,
  signInWithPopup,
  storage,
  ref,
  uploadBytes,
} from "./Firebase";

const Home = ({ currentUserEmail, checkingAuth, isUpdate }) => {
  const [outerMenuDisplay, setOuterMenuDisplay] = useState("none");

  const [signInTranslate, setSignInTranslate] = useState(100);
  const [signInOpacity, setSignInOpacity] = useState(0);
  const [signInZIndex, setSignInZindex] = useState(-1);

  const [firstTranslate, setFirstTranslate] = useState(100);
  const [firstDisplay, setFirstDisplay] = useState(0);
  const [firstZIndex, setFirstZindex] = useState(-1);

  const [secondOpacity, setSecondOpacity] = useState(0);
  const [secondZIndex, setSecondZindex] = useState(-1);

  const [thirdOpacity, setThirdOpacity] = useState(0);
  const [thirdZIndex, setThirdZindex] = useState(-1);

  const [fourthOpacity, setFourthOpacity] = useState(0);
  const [fourthZIndex, setFourthZindex] = useState(-1);

  const [fifthOpacity, setFifthOpacity] = useState(0);
  const [fifthZIndex, setFifthZindex] = useState(-1);

  const [sixthOpacity, setSixthOpacity] = useState(0);
  const [sixthZIndex, setSixthZindex] = useState(-1);

  const [fistYesNoBtn, setFistYesNoBtn] = useState(true);
  const [secondYesNoBtn, setSecondYesNoBtn] = useState(true);

  const [passwordError, setPasswordError] = useState("none");

  const [errorText, setErrorText] = useState(0);
  const [errorTranslate, setErrorTranslate] = useState(1600);

  const [phError, setPhError] = useState("none");
  const [phTranslate, setPhTranslate] = useState(0);

  const [cgpaError, setCgpaError] = useState("none");
  const [cgpaTranslate, setCgpaTranslate] = useState(50);

  const [batchError, setBatchError] = useState("none");
  const [batchTranslate, setBatchTranslate] = useState(50);

  const [isLoading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    signInEmail: "",
    password: "",
    email: "",
    phNumber: "",
    dob: "",
    collegeName: "",
    courseName: "",
    passoutYear: "",
    cgpa: "",
    videoLink: "",
    knowAboutProgram: "",
    whyYouWant: "",
    whatExcites: "",
    otherDetails: "",
  });

  const navigate = useNavigate();

  formData.email = currentUserEmail;

  let buttonText = "";

  // ********** ERROR REGEX VARIABLES *****

  const mobileNumberRegex = /^\d{10}$/;
  const cgpaRegex = /^\d+\.\d+$/;
  const passwordRegex = /^.{6,}$/;
  const batchRegex = /^20\d{2}$/;

  // ********** END ERROR REGEX VARIABLES *****

  // ****** BUTTON TOGGLE VARIABLES *******

  let firstYesBtnColor = "#fb2300";
  let firstNoBtnColor = "black";
  let secondYesBtnColor = "#fb2300";
  let secondNoBtnColor = "black";

  // ****** END BUTTON TOGGLE VARIABLES *******

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  if (checkingAuth && !isUpdate) {
    buttonText = "UPDATE PROFILE";
  } else if (checkingAuth && isUpdate) {
    buttonText = "APPLICATION STATUS";
  } else {
    buttonText = "APPLY NOW";
  }

  // ************ LOGIN FUNCTION *************

  const login = () => {
    document.body.style.overflowY = "scroll";

    signInWithPopup(auth, gProvider).then(async () => {
      setOuterMenuDisplay("none");
      setSignInZindex(-1);
      setSignInTranslate(100);
      setSignInOpacity(0);
    });
  };

  // ************ END LOGIN FUNCTION *************

  // ******** SIGN IN DIV FUNCTION *********

  const showSignInDiv = () => {
    document.body.style.overflow = "hidden";
    setOuterMenuDisplay("flex");
    setSignInZindex(100);
    setSignInTranslate(0);
    setSignInOpacity(1);
  };

  // ******** END SIGN IN DIV FUNCTION *********

  // ********* FIRST SLIDE SHOW DIV FUNCTION *********
  const showDiv = () => {
    document.body.style.overflow = "hidden";
    setOuterMenuDisplay("flex");

    setFirstDisplay("visible");
    setFirstZindex(100);
    setFirstTranslate(0);

    setSecondOpacity(0);
    setSecondZindex(-4);

    setFourthOpacity(0);
    setFourthZindex(-4);

    setFifthOpacity(0);
    setFifthZindex(-4);

    setSixthOpacity(0);
    setSixthZindex(-4);

    setThirdOpacity(0);
    setThirdZindex(-4);
  };

  // ********* END FIRST SLIDE SHOW DIV FUNCTION *********

  // ********** ALL SLIDES CHANGING FUNCTION *************

  const nextSlide = (check) => {
    const { fName, lName, email, phNumber, dob } = formData;
    if (check === "secondShow") {
      if (fName && lName && email && phNumber && dob) {
        if (phError === "none" && passwordError === "none") {
          setFirstDisplay("hidden");
          setFirstZindex(-4);

          setThirdOpacity(0);
          setThirdZindex(-4);

          setFourthOpacity(0);
          setFourthZindex(-4);

          setFifthOpacity(0);
          setFifthZindex(-4);

          setSixthOpacity(0);
          setSixthZindex(-4);

          setSecondOpacity(1);
          setSecondZindex(4);

          setErrorText(0);
          setErrorTranslate(1600);
        }
      } else {
        setErrorText(1);
        setErrorTranslate(0);
      }
    }
    const { collegeName, courseName, passoutYear, cgpa } = formData;
    if (check === "thirdShow") {
      if (collegeName && courseName && passoutYear && cgpa) {
        if (cgpaError === "none" && batchError === "none") {
          setSecondOpacity(0);
          setSecondZindex(-4);

          setFirstDisplay("hidden");
          setFirstZindex(-4);

          setFourthOpacity(0);
          setFourthZindex(-4);

          setFifthOpacity(0);
          setFifthZindex(-4);

          setSixthOpacity(0);
          setSixthZindex(-4);

          setThirdOpacity(1);
          setThirdZindex(4);

          setErrorText(0);
          setErrorTranslate(1600);
        }
      } else {
        setErrorText(1);
        setErrorTranslate(0);
      }
    }

    if (check === "fourthShow") {
      setThirdOpacity(0);
      setThirdZindex(-4);

      setFirstDisplay("hidden");
      setFirstZindex(-4);

      setSecondOpacity(0);
      setSecondZindex(-4);

      setFifthOpacity(0);
      setFifthZindex(-4);

      setSixthOpacity(0);
      setSixthZindex(-4);

      setFourthOpacity(1);
      setFourthZindex(4);
    }

    if (check === "fifthShow") {
      setFirstDisplay("hidden");
      setFirstZindex(-4);

      setThirdOpacity(0);
      setThirdZindex(-4);

      setSecondOpacity(0);
      setSecondZindex(-4);

      setSixthOpacity(0);
      setSixthZindex(-4);

      setFourthOpacity(0);
      setFourthZindex(-4);

      setFifthOpacity(1);
      setFifthZindex(4);
    }

    const { videoLink } = formData;

    if (check === "sixthShow") {
      if (videoLink) {
        setFirstDisplay("hidden");
        setFirstZindex(-4);

        setThirdOpacity(0);
        setThirdZindex(-4);

        setSecondOpacity(0);
        setSecondZindex(-4);

        setSixthOpacity(0);
        setSixthZindex(-4);

        setFourthOpacity(0);
        setFourthZindex(-4);

        setFifthOpacity(0);
        setFifthZindex(-4);

        setSixthOpacity(1);
        setSixthZindex(4);

        setErrorText(0);
        setErrorTranslate(1600);
      } else {
        setErrorText(1);
        setErrorTranslate(0);
      }
    }
  };

  // ********** END ALL SLIDES CHANGING FUNCTION *************

  // ********** FORM CLOSE FUNCTION ************

  const closeForm = (check) => {
    document.body.style.overflowY = "scroll";
    if (check === "signIn") {
      setOuterMenuDisplay("none");
      setSignInTranslate(100);
      setSignInOpacity(0);
      setSignInZindex(-4);

      setErrorText(0);
      setErrorTranslate(1600);
    }
    if (check === "first") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setFirstZindex(-4);
    }
    if (check === "second") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setSecondOpacity(0);
      setSecondZindex(-4);
    }
    if (check === "third") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setThirdOpacity(0);
      setThirdZindex(-4);
    }
    if (check === "fourth") {
      setOuterMenuDisplay("none");
      setFourthOpacity(0);
      setFourthZindex(-4);
    }
    if (check === "fifth") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setFifthOpacity(0);
      setFifthZindex(-4);
    }
    if (check === "sixth") {
      setOuterMenuDisplay("none");
      setFirstTranslate(100);
      setSixthOpacity(0);
      setSixthZindex(-4);
    }
  };

  // ********** FORM CLOSE FUNCTION ************

  // ************ BUTTON TOGGLE FUNCTION *********

  const firstYesNoToggle = (e, isYes) => {
    e.preventDefault();
    if (isYes === "yes") {
      setFistYesNoBtn(true);
    } else {
      setFistYesNoBtn(false);
    }
  };

  const secondYesNoToggle = (e, isYes) => {
    e.preventDefault();
    if (isYes === "yes") {
      setSecondYesNoBtn(true);
    } else {
      setSecondYesNoBtn(false);
    }
  };

  if (!fistYesNoBtn) {
    firstNoBtnColor = "#fb2300";
    firstYesBtnColor = "black";
  } else {
    firstNoBtnColor = "black";
    firstYesBtnColor = "#fb2300";
  }

  if (!secondYesNoBtn) {
    secondNoBtnColor = "#fb2300";
    secondYesBtnColor = "black";
  } else {
    secondNoBtnColor = "black";
    secondYesBtnColor = "#fb2300";
  }

  // ************ BUTTON TOGGLE FUNCTION *********

  // ********** HANDLE FORM INPUT FUNCTION ********

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });

    if (name === "phNumber") {
      let checkNumber = mobileNumberRegex.test(value);
      if (!checkNumber) {
        setPhError("block");
        setPhTranslate(0);
      } else {
        setPhError("none");
        setPhTranslate(30);
      }
    }

    if (name === "cgpa") {
      let checkCgpa = cgpaRegex.test(value);
      if (!checkCgpa) {
        setCgpaError("block");
        setCgpaTranslate(0);
      } else {
        setCgpaError("none");
        setCgpaTranslate(50);
      }
    }

    if (name === "password") {
      let checkPassword = passwordRegex.test(value);
      if (!checkPassword) {
        setPasswordError("block");
      } else {
        setPasswordError("none");
      }
    }

    if (name === "passoutYear") {
      let checkBatch = batchRegex.test(value);
      if (!checkBatch) {
        setBatchError("block");
        setBatchTranslate(0);
      } else {
        setBatchError("none");
        setBatchTranslate(50);
      }
    }
  };

  // ********** HANDLE FORM INPUT FUNCTION ********

  // ********** FORM DATA UPLOAD FUNCTION ***********

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    document.body.style.overflowY = "scroll";
    e.preventDefault();
    let letter = false;
    if (selectedFile) letter = true;

    const {
      fName,
      lName,
      email,
      phNumber,
      dob,
      collegeName,
      courseName,
      passoutYear,
      cgpa,
      videoLink,
      knowAboutProgram,
      whyYouWant,
      whatExcites,
      otherDetails,
    } = formData;
    const fullName = fName + " " + lName;

    if (whyYouWant && whatExcites && otherDetails) {
      await setDoc(doc(db, "phase1", email), {
        name: fullName,
        email,
        phNumber,
        dob,
        collegeName,
        courseName,
        passoutYear,
        cgpa,
        anyExperienceInCommunity: fistYesNoBtn,
        courseExperienceOfHosting: secondYesNoBtn,
        videoLink,
        knowAboutProgram,
        letter: letter,
        whyYouWant,
        whatExcites,
        otherDetails,
        phase: 1,
      });

      const folderRef = ref(storage, "recommendation-letters");
      const fileRef = ref(folderRef, `${email}-letter.pdf`);
      uploadBytes(fileRef, selectedFile);

      setSixthOpacity(0);
      setSixthZindex(-4);
      setLoading(true);

      const response = await axios.post(
        "https://us-central1-student-ambassador-program.cloudfunctions.net/server/api/applying-mail",
        {
          name: fullName,
          email: email,
        }
      );
      if (response) {
        setOuterMenuDisplay("none");
        setLoading(false);
        window.location.reload();
      }
    } else {
      setErrorText(1);
      setErrorTranslate(0);
    }
  };

  // ********** END FORM DATA UPLOAD FUNCTION ***********

  return (
    <>
      {isLoading ? (
        <>
          <div>
            <Loader />
          </div>
        </>
      ) : (
        <></>
      )}

      <div
        style={{
          display: `${outerMenuDisplay}`,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "fixed",
          height: "100vh",
          right: "0",
          left: "0",
          top: "0",
          zIndex: "2",
        }}
      ></div>

      {/* ************ SIGN IN DIV ************ */}

      <div
        className="outerDiv"
        style={{
          transition: "all 0.8s ease-in",
          opacity: `${signInOpacity}`,
          transformOrigin: "bottom",
          transform: `translateY(${signInTranslate}%)`,
          zIndex: `${signInZIndex}`,
        }}
      >
        <div className="col-md-5 col-sm-6 col-11 innerDiv" id="hiddenDiv">
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("signIn");
              }}
            ></i>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12 col-12 desktopSignIn">
              <div className="text-center mt-0 mb-4">
                <div
                  className="row signInGoogle justify-content-center align-items-center"
                  onClick={login}
                >
                  <div className="col-3 text-center">
                    <Image fluid src={googleLogo}></Image>
                  </div>
                  <div className="col-9 text-center signInText">
                    SIGN IN WITH GOOGLE
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-12 mobileSignIn">
              <div className="text-center mt-0 mb-4">
                <div
                  className="row signInGoogle justify-content-center align-items-center"
                  onClick={login}
                >
                  <div className="col-3 text-center">
                    <Image fluid src={googleLogo}></Image>
                  </div>
                  <div className="col-7 signInText">SIGN IN</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ************ END SIGN IN DIV ************** */}

      {/* ********* HIDDEN DIV ************ */}

      <div
        className="outerDiv"
        style={{
          transition: "all 0.8s ease-in",
          transformOrigin: "bottom",
          transform: `translateY(${firstTranslate}%)`,
          zIndex: `${firstZIndex}`,
          visibility: `${firstDisplay}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv" id="hiddenDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("first");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                Personal <span className="mainHeading">Details</span>{" "}
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
                transform: `translateY(${errorTranslate}%)`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-11 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-3 col-12 formLable mb-4 mobileNone">
                    My name is
                  </div>
                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="fName"
                        value={formData.fName}
                        onChange={handleInput}
                        placeholder="First Name*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="lName"
                        value={formData.lName}
                        onChange={handleInput}
                        placeholder="Last Name*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-6 col-12 mb-4 formLable mobileNone">
                    and the best email to reach is
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="email">
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInput}
                        placeholder="Email Address*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-5 col-12 mb-4 formLable mobileNone">
                    or you can contact me at
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="number">
                      <Form.Control
                        type="text"
                        name="phNumber"
                        value={formData.phNumber}
                        onChange={handleInput}
                        placeholder="9876543210*"
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-3 col-12 mb-4"
                    style={{
                      transformOrigin: "right",
                      transition: "all ease-in-out .3s",
                      transform: `translateX(${phTranslate}%)`,
                      color: "#fb2300",
                      display: `${phError}`,
                    }}
                  >
                    Match pattern [9101234567]
                  </div>

                  <div className="col-lg-5 col-12 mb-4 formLable mobileNone">
                    and my date of birth is
                  </div>

                  <div className="col-lg-3 col-12 mb-4">
                    <Form.Group controlId="date">
                      <Form.Control
                        type="text"
                        name="dob"
                        value={formData.dob}
                        onChange={handleInput}
                        placeholder="Date of Birth"
                        onFocus={(e) => (e.target.type = "date")}
                        max="datePickerId"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-2 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("secondShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********* END HIDDEN DIV ************ */}

      {/* ********** SECOND HIDDEN DIV ************* */}

      <div
        className="outerDiv"
        style={{
          opacity: `${secondOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${secondZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("second");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                College <span className="mainHeading">Details</span>{" "}
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
                transform: `translateY(${errorTranslate}%)`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-11 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-3 col-12 formLable mb-4 mobileNone">
                    I studied in
                  </div>
                  <div className="col-lg-6 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="collegeName"
                        value={formData.collegeName}
                        onChange={handleInput}
                        placeholder="College Name*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-3 col-12 formLable mb-4 mobileNone"></div>

                  <div className="col-lg-3 col-12 mb-4 formLable mobileNone">
                    in the field of
                  </div>

                  <div className="col-lg-5 col-12 mb-4">
                    <Form.Group controlId="text">
                      <Form.Control
                        type="text"
                        name="courseName"
                        value={formData.courseName}
                        onChange={handleInput}
                        placeholder="B.Tech*"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-12 formLable mb-4 mobileNone"></div>

                  <div className="col-lg-4 col-12 mb-4 formLable mobileNone">
                    and my CGPA is
                  </div>

                  <div className="col-lg-3 col-12 mb-4">
                    <Form.Group controlId="number">
                      <Form.Control
                        type="text"
                        name="cgpa"
                        value={formData.cgpa}
                        onChange={handleInput}
                        placeholder="CGPA*"
                      />
                    </Form.Group>
                  </div>

                  <div
                    className="col-lg-3 col-12 mb-4"
                    style={{
                      transformOrigin: "right",
                      transition: "all ease-in-out .3s",
                      transform: `translate(${cgpaTranslate}%)`,
                      color: "#fb2300",
                      display: `${cgpaError}`,
                    }}
                  >
                    Please match pattern [9.0]
                  </div>

                  <div className="col-lg-2 col-12 mb-4 mobileNone"></div>

                  <div className="col-lg-4 col-12 mb-4 formLable mobileNone">
                    in the batch of
                  </div>

                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="number">
                      <Form.Control
                        type="text"
                        name="passoutYear"
                        value={formData.passoutYear}
                        onChange={handleInput}
                        placeholder="Passout Year"
                      />
                    </Form.Group>
                  </div>

                  <div
                    className="col-lg-3 col-12 mb-4"
                    style={{
                      transformOrigin: "right",
                      transition: "all ease-in-out .3s",
                      transform: `translateX(${batchTranslate}%)`,
                      color: "#fb2300",
                      display: `${batchError}`,
                    }}
                  >
                    Please match pattern [ 2022 ]
                  </div>
                </div>
                <div className="text-center mt-2 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("thirdShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********* END SECOND HIDDEN DIV ********* */}

      {/* ********* THIRD HIDDEN DIV ******** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${thirdOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${thirdZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("third");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "40vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                Past <span className="mainHeading">Experience</span>
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-9 col-12 formLable mb-2">
                    Any Experience in Community Lead / Volunteer in the past?
                  </div>

                  <div className="col-lg-3 col-12 mb-4">
                    <button
                      className="yesBtn"
                      style={{ backgroundColor: `${firstYesBtnColor}` }}
                      onClick={(e) => {
                        firstYesNoToggle(e, "yes");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="noBtn"
                      style={{ backgroundColor: `${firstNoBtnColor}` }}
                      onClick={(e) => {
                        firstYesNoToggle(e, "no");
                      }}
                    >
                      No
                    </button>
                  </div>

                  <div className="col-lg-9 col-12 formLable mb-2">
                    Course Experience of hosting events in the past?
                  </div>
                  <div className="col-lg-3 col-12 mb-4">
                    <button
                      className="yesBtn"
                      style={{ backgroundColor: `${secondYesBtnColor}` }}
                      onClick={(e) => {
                        secondYesNoToggle(e, "yes");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="noBtn"
                      style={{ backgroundColor: `${secondNoBtnColor}` }}
                      onClick={(e) => {
                        secondYesNoToggle(e);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className="text-center mt-3 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("fourthShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>{" "}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********* END THIRD HIDDEN DIV ********* */}

      {/* ******** FOURTH HIDDEN DIV *********** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${fourthOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${fourthZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("fourth");
              }}
            ></i>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">Recommendations</h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="col-lg-8 col-12 formLable mb-4">
                    Recommendation Letter by TPO / Registrar (Optional)
                  </div>
                  <div className="col-lg-4 col-12 mb-4">
                    <Form.Group controlId="file">
                      <Form.Control
                        type="file"
                        accept=".pdf"
                        name="uploadedFile"
                        onChange={handleFileUpload}
                        placeholder="College Name*"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-4 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("fifthShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* *********** END FOURTH HIDDEN DIV ********* */}

      {/* *********** FIFTH HIDDEN DIV *********** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${fifthOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${fifthZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("fifth");
              }}
            ></i>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                About <span className="mainHeading">You</span>
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div
                  className="row align-items-center"
                  style={{
                    height: "40vh",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div className="fs-4 col-lg-7 col-12 formLable mb-4">
                    Short Intro Video Explaining yourself <br /> and experience
                    in 180 seconds
                  </div>
                  <div className="col-lg-5 col-12 mb-4">
                    <Form.Group controlId="url">
                      <Form.Control
                        type="url"
                        name="videoLink"
                        value={formData.videoLink}
                        onChange={handleInput}
                        placeholder="Enter Link*"
                      />
                    </Form.Group>
                  </div>

                  <div className="fs-4 col-lg-12 col-12 formLable mb-4">
                    How you got to know about this program
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="knowAboutProgram"
                        value={formData.knowAboutProgram}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-4 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={() => {
                      nextSlide("sixthShow");
                    }}
                  >
                    Next <span style={{ letterSpacing: "0px" }}>&gt; &gt;</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* ********** END FIFTH HIDDEN DIV ********** */}

      {/* ********** SIXTH HIDDEN DIV *********** */}

      <div
        className="outerDiv"
        style={{
          opacity: `${sixthOpacity}`,
          transformOrigin: "bottom",
          zIndex: `${sixthZIndex}`,
        }}
      >
        <div className="col-md-8 col-12 innerDiv">
          <div className="d-flex ms-2 mt-2">
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                showDiv();
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("secondShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("thirdShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fourthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("fifthShow");
              }}
            ></div>
            <div
              className="circle ms-1"
              style={{
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                nextSlide("sixthShow");
              }}
            ></div>
          </div>
          <div className="mb-0" style={{ textAlign: "right" }}>
            <i
              class="fa-regular fa-circle-xmark fa-spin icnStyle"
              onClick={() => {
                closeForm("sixth");
              }}
            ></i>
          </div>

          <div
            className="row justify-content-center"
            style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="col-12 text-center">
              <h1 className="mainHeading2 text-dark">
                What Excites <span className="mainHeading">You the Most?</span>{" "}
              </h1>
            </div>

            <div
              className="text-center errorText mt-1 mb-3"
              style={{
                transition: "all 0.5s ease-in-out",
                transformOrigin: "left",
                color: "#fb2300",
                opacity: `${errorText}`,
              }}
            >
              All Fields Required
            </div>

            <div className="col-lg-10 col-12">
              <Form>
                <div className="row align-items-center">
                  <div className="fs-4 col-lg-12 col-12 formLable mb-2">
                    Why you want to be Campus Ambassador for Alphanumeric Ideas
                    at your campus?
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="whyYouWant"
                        value={formData.whyYouWant}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>

                  <div className="fs-4 col-lg-12 col-12 formLable mb-2">
                    What excites you the most about this program?
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="whatExcites"
                        value={formData.whatExcites}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>

                  <div className="fs-4 col-lg-12 col-12 formLable mb-2">
                    Any other details you would love to share?
                  </div>
                  <div className="col-lg-12 col-12 mb-4">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        name="otherDetails"
                        value={formData.otherDetails}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-4 mb-1">
                  <Button
                    className="mainBtn"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* *********** END SIXTH HIDDEN DIV *********** */}

      <Container fluid>
        <>
          <div className="row justify-content-center align-items-center mt-5">
            <div className="col-md-6 text-center mb-5">
              <h1 className="mainHeading2">
                Ignite your <span className="mainHeading">ideas</span> beyond
                your <span className="mainHeading">imagination</span> with{" "}
                <br />
              </h1>
              <h1 className="middleText">
                <span className="mainHeading">
                  GIGT Student Ambassador Program
                </span>
                <br />
              </h1>
              <h1 className="mainHeading2">
                <span>you would be proud to be part of!</span>
              </h1>
            </div>
            <div className="col-md-5 mb-4">
              <Image
                style={{ borderRadius: "54px" }}
                fluid
                src={mainImg}
                alt="Main Image"
              />
            </div>
            <div className="col-md-4 col-11 mt-5 text-center">
              <Button
                className="mainBtn"
                onClick={(e) => {
                  if (buttonText === "APPLICATION STATUS") {
                    navigate("/application-status");
                  } else if (buttonText === "APPLY NOW") {
                    showSignInDiv(e);
                  } else {
                    showDiv(e);
                  }
                }}
              >
                {buttonText}
              </Button>
            </div>
            <div className="col-md-4 col-11 mt-5 text-center">
              <Button
                className="mainBtn"
                onClick={() => {
                  navigate("/about");
                }}
              >
                ABOUT
              </Button>
            </div>

            <div className="col-12 text-center mt-5">
              {checkingAuth ? (
                <>
                  {currentUserEmail === "mohit@alphanumericideas.com" ||
                  currentUserEmail === "prakeet@alphanumericideas.com" ||
                  currentUserEmail === "abhishekgautam@alphanumericideas.com" ||
                  currentUserEmail === "zippinder@alphanumericideas.com" ? (
                    <>
                      <Button
                        className="mainBtn"
                        onClick={() => {
                          navigate("/users");
                        }}
                      >
                        DASHBOARD
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
        <div className="row justify-content-center">
          <div className="col-md-12 points">
            <div className="row justify-content-center">
              <div className="col-md-12 col-12">
                <Accordion className="mb-5">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="textHeading">
                      What is GIGT Program all about?
                    </Accordion.Header>
                    <Accordion.Body className="accordText">
                      GIGT - Great Ideas Grow Together Student Ambassador
                      Program is inclined towards supporting, crafting and
                      pushing amazing and innovative ideas among students across
                      India.GIGT - Great Ideas Grow Together Student Ambassador
                      Program is inclined towards supporting, crafting and
                      pushing amazing and innovative ideas among students across
                      India.
                      <Accordion className="mt-4">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            As GIGT Student Ambassador, you would be responsible
                            for:
                          </Accordion.Header>
                          <Accordion.Body className="accordText">
                            - Connect your friends to ignite ideas to do
                            something creative. <br /> - Host Events to boost
                            Aptitude, Business and Management Skills. <br /> -
                            Will be responsible for leading student chapter at
                            your campus. <br /> - Will be responsible for
                            hosting events as per guidelines and learning
                            resources shared by Alphanumeric Ideas. <br /> -
                            Chance to be part of Annual Campus Ambassador Summit
                            in Jan-Feb of every year.
                            <br /> <br />
                            Note: This is not a regular Campus Ambassador cum
                            Sales Program; we only value ideas, and the program
                            will give a new flight to your imagination and
                            ideas. We never ask anybody to promote sales / sell
                            courses nor offer commissions against it. It's a
                            unique experience to give your ideas the boost it
                            deserves!
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Who can become a GIGT Student Ambassador?
                    </Accordion.Header>
                    <Accordion.Body className="accordText">
                      - Display continued activity in the community
                      efforts/startup ecosystem <br /> - Be an influential and
                      recognized speaker at startup conferences <br /> - Loves
                      to innovate/ideate new ideas <br /> - Loves to give back
                      to the community <br /> - Can articulate clearly and
                      provide meaningful advice to others.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Application Process</Accordion.Header>
                    <Accordion.Body className="accordText">
                      The GIGT Student Ambassador program has a three-step
                      application process: application form, ideation interview,
                      and ambassador interview. <br />
                      <Accordion className="mt-4">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Step 1: Application Form
                          </Accordion.Header>
                          <Accordion.Body className="accordText">
                            The application form asks about your experience,
                            community contributions, and motivation to join the
                            program. Be sure to fill out the form carefully and
                            highlight your impact and contributions over a
                            prolonged period. If you have also organized events,
                            mention this in the application form.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Step 2: Ideation Interview
                          </Accordion.Header>
                          <Accordion.Body className="accordText">
                            If you are shortlisted after the first step, you
                            will be interviewed by some of the Alphanumeric
                            Ideas team members. The interviewer will focus on
                            your ideation process and thoughts. This is not a
                            job interview but a conversation with someone as
                            passionate about ideas as you are.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            Step 3: Social Impact Interview
                          </Accordion.Header>
                          <Accordion.Body className="accordText">
                            The final step is a social impact interview. This
                            interview will get to know you, your motivation to
                            join the program, and your expertise in the category
                            you are applying for.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What Happens When I Become a GIGT Student Ambassador?
                    </Accordion.Header>
                    <Accordion.Body className="accordText">
                      If accepted into the GIGT Student Ambassador program, you
                      will be welcomed into a community of passionate and
                      motivated students. You will receive training, mentorship,
                      and access to resources. You will also be able to organize
                      events, create content, and give talks.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      My Application Got Rejected. What Do I Do?
                    </Accordion.Header>
                    <Accordion.Body className="accordText">
                      If your application is rejected, don't worry! There are
                      many reasons why an application might be rejected, and you
                      still need to be a good candidate. Keep helping the
                      student community, and you can always reapply.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Here are some tips for improving your application:
                    </Accordion.Header>
                    <Accordion.Body className="accordText">
                      - Fill out the application form carefully and highlight
                      your impact and contributions. <br />
                      - Organize events or create content to demonstrate your
                      leadership and passion. <br />- Network with other GIGT
                      Student Ambassadors to learn more about the program.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
