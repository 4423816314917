import React from "react";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/esm/Image";
import fb from "../images/facebook.png";
import twitter from "../images/twitter.png";
import linkedin from "../images/linkedin.png";
import footerLogo from "../images/footer.png";
import insta from "../images/instagram.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container fluid>
        <div className="row justify-content-around align-items-center footerBg">
          <div className="col-md-3 col-sm-8 col-11 text-center mb-4 mt-4">
            <Image
              fluid
              className="footerLogo"
              src={footerLogo}
              onClick={() => {
                window.scroll(0, 0);
                navigate("/");
              }}
            ></Image>
          </div>
          <div className="col-md-4 col-11 mb-4">
            <div className="row justify-content-evenly">
              <div
                className="col-lg-2 col-sm-5 text-center footerText2 footerButton"
                onClick={() => {
                  window.scroll(0, 0);
                  navigate("/");
                }}
              >
                Home
              </div>
              <div
                className="col-lg-2 col-sm-5 text-center footerText2 footerButton"
                onClick={() => {
                  window.scroll(0, 0);
                  navigate("/about");
                }}
              >
                About
              </div>
              {/* <div className="col-lg-2 col-sm-5 text-center footerText2 footerButton">
                Privacy
              </div>
              <div className="col-lg-2 col-sm-5 text-center footerText2 footerButton">
                Term
              </div> */}
            </div>
          </div>
          <div className="col-md-3 col-11 text-center">
            <p className="footerText">Follow us</p>
            <div className="row justify-content-around">
              <div className="col-md-2 col-sm-1 col-2">
                <a
                  href="https://www.facebook.com/AlphanumericIdeas"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image className="footerIcon" fluid src={fb} alt="facebook" />
                </a>
              </div>
              <div className="col-md-2 col-sm-1 col-2">
                <a
                  href="https://twitter.com/AiAlphanumeric"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="footerIcon"
                    fluid
                    src={twitter}
                    alt="twitter"
                  />
                </a>
              </div>
              <div className="col-md-2 col-sm-1 col-2">
                <a
                  href="https://www.linkedin.com/company/alphanumeric-ideas"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="footerIcon"
                    fluid
                    src={linkedin}
                    alt="linkedin"
                  />
                </a>
              </div>
              <div className="col-md-2 col-sm-1 col-2">
                <a
                  href="https://www.instagram.com/alphanumericideas/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="footerIcon"
                    fluid
                    src={insta}
                    alt="instagram"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/* <Container fluid>
        <div className="row justify-content-around footerBg">
          <div className="col-sm-4 col-12 text-center">
            <Image fluid src={footerLogo}></Image>
          </div>

          <div className="col-sm-4 text-center">
            <div className="mt-5 col-sm-8 d-flex justify-content-around">
              <p className="footerText2 footerButton">Privacy</p>
              <p className="footerText2 footerButton">Term</p>
              <p
                className="footerText2 footerButton"
                onClick={() => {
                  navigate("/about");
                }}
              >
                About
              </p>
            </div>
          </div>

          <div className="col-sm-4 col-12 text-center">
            <div className="row">
              <div className="col-sm-7 col-12 text-center mb-2">
                <p className="footerText">Follow us</p>
              </div>

            </div>

            <div className="col-sm-6 d-flex justify-content-around">
              <a
                className="footerLogo"
                href="https://www.facebook.com/AlphanumericIdeas"
                target="_blank"
                rel="noreferrer"
              >
                <Image fluid src={fb} alt="facebook" />
              </a>
              <a
                className="footerLogo"
                href="https://twitter.com/AiAlphanumeric"
                target="_blank"
                rel="noreferrer"
              >
                <Image fluid src={twitter} alt="twitter" />
              </a>
              <a
                className="footerLogo"
                href="https://www.linkedin.com/company/alphanumeric-ideas"
                target="_blank"
                rel="noreferrer"
              >
                <Image fluid src={linkedin} alt="linkedin" />
              </a>
              <a
                className="footerLogo"
                href="https://www.instagram.com/alphanumericideas/"
                target="_blank"
                rel="noreferrer"
              >
                <Image fluid src={insta} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </Container> */}
    </>
  );
};

export default Footer;
