import { useEffect } from "react";

const Title = ({ title }) => {
  useEffect(() => {
    window.document.title = title;
  }, [title]);
  return <></>;
};

export default Title;
