const firebaseConfig = {
  apiKey: "AIzaSyB6rFLM6o6x71I8kFlP2hWj9KT2T5ftvR0",
  authDomain: "student-ambassador-program.firebaseapp.com",
  projectId: "student-ambassador-program",
  storageBucket: "student-ambassador-program.appspot.com",
  messagingSenderId: "823802926180",
  appId: "1:823802926180:web:89b9b236a645c3addbbb71",
};

module.exports = firebaseConfig;
