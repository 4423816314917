import React from 'react'

const Loader = () => {
  return (
    <>
    <div className="spinner-container">
        <div className="loading-spinner"></div>
        </div>
    </>
  )
}

export default Loader