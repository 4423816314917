import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/esm/Container";

const About = () => {
  return (
    <>
      <Container fluid>
        <div className="row justify-content-center mt-5">
          <div className="col-md-11 mt-4 mb-5">
            <h2 className="mb-5">ABOUT</h2>
            <Accordion className="mb-5">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="textHeading">
                  What is GIGT Program all about?
                </Accordion.Header>
                <Accordion.Body className="accordText">
                  GIGT - Great Ideas Grow Together Student Ambassador Program is
                  inclined towards supporting, crafting and pushing amazing and
                  innovative ideas among students across India.GIGT - Great
                  Ideas Grow Together Student Ambassador Program is inclined
                  towards supporting, crafting and pushing amazing and
                  innovative ideas among students across India.
                  <Accordion className="mt-4">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        As GIGT Student Ambassador, you would be responsible
                        for:
                      </Accordion.Header>
                      <Accordion.Body className="accordText">
                        - Connect your friends to ignite ideas to do something
                        creative. <br /> - Host Events to boost Aptitude,
                        Business and Management Skills. <br /> - Will be
                        responsible for leading student chapter at your campus.{" "}
                        <br /> - Will be responsible for hosting events as per
                        guidelines and learning resources shared by Alphanumeric
                        Ideas. <br /> - Chance to be part of Annual Campus
                        Ambassador Summit in Jan-Feb of every year.
                        <br /> <br />
                        Note: This is not a regular Campus Ambassador cum Sales
                        Program; we only value ideas, and the program will give
                        a new flight to your imagination and ideas. We never ask
                        anybody to promote sales / sell courses nor offer
                        commissions against it. It's a unique experience to give
                        your ideas the boost it deserves!
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Who can become a GIGT Student Ambassador?
                </Accordion.Header>
                <Accordion.Body className="accordText">
                  - Display continued activity in the community efforts/startup
                  ecosystem <br /> - Be an influential and recognized speaker at
                  startup conferences <br /> - Loves to innovate/ideate new
                  ideas <br /> - Loves to give back to the community <br /> -
                  Can articulate clearly and provide meaningful advice to
                  others.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Application Process</Accordion.Header>
                <Accordion.Body className="accordText">
                  The GIGT Student Ambassador program has a three-step
                  application process: application form, ideation interview, and
                  ambassador interview. <br />
                  <Accordion className="mt-4">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Step 1: Application Form
                      </Accordion.Header>
                      <Accordion.Body className="accordText">
                        The application form asks about your experience,
                        community contributions, and motivation to join the
                        program. Be sure to fill out the form carefully and
                        highlight your impact and contributions over a prolonged
                        period. If you have also organized events, mention this
                        in the application form.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Step 2: Ideation Interview
                      </Accordion.Header>
                      <Accordion.Body className="accordText">
                        If you are shortlisted after the first step, you will be
                        interviewed by some of the Alphanumeric Ideas team
                        members. The interviewer will focus on your ideation
                        process and thoughts. This is not a job interview but a
                        conversation with someone as passionate about ideas as
                        you are.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Step 3: Social Impact Interview
                      </Accordion.Header>
                      <Accordion.Body className="accordText">
                        The final step is a social impact interview. This
                        interview will get to know you, your motivation to join
                        the program, and your expertise in the category you are
                        applying for.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What Happens When I Become a GIGT Student Ambassador?
                </Accordion.Header>
                <Accordion.Body className="accordText">
                  If accepted into the GIGT Student Ambassador program, you will
                  be welcomed into a community of passionate and motivated
                  students. You will receive training, mentorship, and access to
                  resources. You will also be able to organize events, create
                  content, and give talks.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  My Application Got Rejected. What Do I Do?
                </Accordion.Header>
                <Accordion.Body className="accordText">
                  If your application is rejected, don't worry! There are many
                  reasons why an application might be rejected, and you still
                  need to be a good candidate. Keep helping the student
                  community, and you can always reapply.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Here are some tips for improving your application:
                </Accordion.Header>
                <Accordion.Body className="accordText">
                  - Fill out the application form carefully and highlight your
                  impact and contributions. <br />
                  - Organize events or create content to demonstrate your
                  leadership and passion. <br />- Network with other GIGT
                  Student Ambassadors to learn more about the program.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;
