import "./App.css";
import Home from "./components/Home";
import Title from "./components/Title";
import About from "./components/About";
import UserData from "./components/UserData";
import Phase2 from "./components/Phase2";
import Navbar from "./components/Navbar";
import LetterViewer from "./components/LetterViewer";
import Phase3 from "./components/Phase3";
import Phase5 from "./components/Phase5";
import Phase4 from "./components/Phase4";
import Rejected from "./components/Rejected";
import ApplicationStatus from "./components/ApplicationStatus";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  auth,
  onAuthStateChanged,
  doc,
  db,
  getDoc,
} from "./components/Firebase";

function App() {
  const [checkingAuth, setCheckingAuth] = useState(null);
  const [menubarName, setMenubarName] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [phase, setPhase] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const currentUser = user;
        setCurrentUserEmail(currentUser.email);
        setMenubarName(currentUser.displayName);
        setCheckingAuth(user);

        const docRef = doc(db, "phase1", currentUser.email);
        const docSnap = await getDoc(docRef);

        const docRef2 = doc(db, "phase2", currentUser.email);
        const docSnap2 = await getDoc(docRef2);

        const docRef3 = doc(db, "phase3", currentUser.email);
        const docSnap3 = await getDoc(docRef3);

        const docRef4 = doc(db, "phase4", currentUser.email);
        const docSnap4 = await getDoc(docRef4);

        const docRef5 = doc(db, "phase5", currentUser.email);
        const docSnap5 = await getDoc(docRef5);

        const rejectedDocRef = doc(db, "rejectedUsers", currentUser.email);
        const rejectedDocSnap = await getDoc(rejectedDocRef);

        if (docSnap.exists()) {
          setIsUpdate(true);
          setPhase(docSnap.data().phase);
        } else if (docSnap2.exists()) {
          setIsUpdate(true);
          setPhase(docSnap2.data().phase);
        } else if (docSnap3.exists()) {
          setIsUpdate(true);
          setPhase(docSnap3.data().phase);
        } else if (docSnap4.exists()) {
          setIsUpdate(true);
          setPhase(docSnap4.data().phase);
        } else if (docSnap5.exists()) {
          setIsUpdate(true);
          setPhase(docSnap5.data().phase);
        } else if (rejectedDocSnap.exists()) {
          setIsUpdate(true);
          setPhase(rejectedDocSnap.data().phase);
        } else {
          setIsUpdate(false);
        }
      } else {
        setCheckingAuth(null);
        setMenubarName("");
        setCurrentUserEmail("");
      }
    });
  }, []);

  return (
    <>
      <Navbar
        checkingAuth={checkingAuth}
        menubarName={menubarName}
        currentUserEmail={currentUserEmail}
        isUpdate={isUpdate}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <>
                <Home
                  currentUserEmail={currentUserEmail}
                  checkingAuth={checkingAuth}
                  isUpdate={isUpdate}
                />
                <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | Home" />
              </>
            </>
          }
        ></Route>

        <Route
          path="application-status"
          element={
            <>
              <ApplicationStatus
                currentUserEmail={currentUserEmail}
                checkingAuth={checkingAuth}
                phase={phase}
              />
              <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | Application Status" />
            </>
          }
        ></Route>

        <Route
          path="/about"
          element={
            <>
              <About />
              <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | About" />
            </>
          }
        ></Route>
        {currentUserEmail === "mohit@alphanumericideas.com" ||
        currentUserEmail === "prakeet@alphanumericideas.com" ||
        currentUserEmail === "abhishekgautam@alphanumericideas.com" ||
        currentUserEmail === "zippinder@alphanumericideas.com" ? (
          <>
            <Route
              path="/users"
              element={
                <>
                  <UserData />
                  <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | User Data" />
                </>
              }
            ></Route>

            <Route
              path="/phase2"
              element={
                <>
                  <Phase2 />
                  <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | User Data" />
                </>
              }
            ></Route>

            <Route
              path="/phase3"
              element={
                <>
                  <Phase3 />
                  <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | User Data" />
                </>
              }
            ></Route>

            <Route
              path="/phase4"
              element={
                <>
                  <Phase4 />
                  <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | User Data" />
                </>
              }
            ></Route>

            <Route
              path="/phase5"
              element={
                <>
                  <Phase5 />
                  <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | User Data" />
                </>
              }
            ></Route>

            <Route
              path="/rejected"
              element={
                <>
                  <Rejected />
                  <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | User Data" />
                </>
              }
            ></Route>

            <Route
              path="/letter-viewer"
              element={
                <>
                  <LetterViewer />
                  <Title title="GIGT Student Ambassador Program: Igniting Ideas in India | Letter Viewer" />
                </>
              }
            ></Route>
          </>
        ) : (
          <></>
        )}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
