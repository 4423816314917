import { initializeApp } from "firebase/app";
import firebaseConfig from "./Config";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const gProvider = new GoogleAuthProvider(app);
const auth = getAuth(app);
const storage = getStorage(app);

export {
  db,
  doc,
  setDoc,
  getDoc,
  gProvider,
  auth,
  signInWithPopup,
  storage,
  ref,
  uploadBytes,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  collection,
  getDocs,
  getDownloadURL,
  updateDoc,
  deleteDoc,
};
